
































































import Mapbox from 'mapbox-gl-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ParcelMapping } from '@/interfaces/parcelMapping';
import { Parcel } from '@/interfaces/parcel';
import { Feature, feature, featureCollection, Polygon } from '@turf/helpers';
import bbox from '@turf/bbox';
import moment from 'moment';
import { OverlapMapping } from '@/interfaces/overlapMapping';
import CreateEntityModal from '@/components/CreateEntityModal.vue';
import { FarmMapping } from '@/interfaces/farmMapping';
import { getFakeId } from '@/services/utils';
import { Map } from 'mapbox-gl';

@Component({
  components: {
    Mapbox,
    CreateEntityModal
  }
})
export default class ManageOverlappingModal extends Vue {
  @Prop() visible: boolean;
  @Prop() shapeParcelNames: ParcelMapping[];
  @Prop() shapeFarmNames: FarmMapping[];
  @Prop() parcels: Parcel[];
  @Prop() farms: Parcel[];
  private map: Map;
  private overlapParcelSourceId = 'overlapParcelSourceId';
  private overlapParcelLayerId = 'overlapParcelLayerId';
  private parcelsSourceId = 'parcelsSourceId';
  private parcelsLayerId = 'parcelsLayerId';
  selectedParcelDB: Parcel = null;
  selectedParcelMappingName: string = null;
  createParcelItemName: string = null;

  get overlappingParcels(): ParcelMapping[] {
    return this.shapeParcelNames.filter((parcel: ParcelMapping) => parcel.overlapMapping.length > 0);
  }

  get selectedParcelMapping(): ParcelMapping {
    return this.shapeParcelNames.find((parcel: ParcelMapping) => parcel.featureName === this.selectedParcelMappingName);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  getParcelDbName(item: ParcelMapping): string {
    if (item.overlapMapping.length > 0) {
      return item.overlapMapping.map((o: OverlapMapping) => `${o.dbName} (${o.percent}%)`).join(', ');
    }
    return item.dbName;
  }

  handleChange(featureName: string): void {
    this.selectedParcelMappingName = featureName;
    this.cleanupLayer(this.overlapParcelLayerId, this.overlapParcelSourceId);
    let selectedParcelDB = null;
    if (this.selectedParcelMapping) {
      selectedParcelDB =
        this.selectedParcelMapping.overlapMapping.length === 1
          ? this.parcels.find((p: Parcel) => p.id === this.selectedParcelMapping.overlapMapping[0].dbId)
          : null;
      this.map.addSource(this.overlapParcelSourceId, {
        type: 'geojson',
        data: featureCollection([feature(this.selectedParcelMapping.featureShape)])
      });
      this.map.addLayer({
        type: 'fill',
        id: this.overlapParcelLayerId,
        source: this.overlapParcelSourceId,
        paint: { 'fill-opacity': 0.5, 'fill-color': 'rgba(200, 100, 240, 1)' }
      });

      const geoBbox = bbox(this.selectedParcelMapping.featureShape);
      this.map.fitBounds(
        [
          [geoBbox[0], geoBbox[3]],
          [geoBbox[2], geoBbox[1]]
        ],
        { duration: 0 }
      );
    }
    this.selectedParcelDB = selectedParcelDB;
  }

  private cleanupLayer(layerId: string, sourceId: string): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  onMapLoaded(map: Map): void {
    this.map = map;
    this.showParcels();
    this.selectNextParcel();
  }

  private showParcels(): void {
    this.cleanupLayer(this.parcelsLayerId, this.parcelsSourceId);
    if (this.parcels.length) {
      this.map.addSource(this.parcelsSourceId, {
        type: 'geojson',
        data: featureCollection(
          this.parcels
            .filter((parcel: Parcel) => !!parcel.Shape)
            .map((parcel: Parcel) => parcel.Shape.GeoJson.features[0] as Feature<Polygon>)
        )
      });
      this.map.addLayer({
        type: 'line',
        id: this.parcelsLayerId,
        source: this.parcelsSourceId,
        paint: { 'line-width': 2, 'line-color': 'darkgray' }
      });
    }
  }

  formatDate(date: string): string {
    return date ? moment(date).format('YYYY-MM-DD') : '-';
  }

  private selectNextParcel(): void {
    if (this.overlappingParcels.length) {
      this.handleChange(this.overlappingParcels[0].featureName);
    } else {
      this.cancel();
    }
  }

  doNothing(): void {
    this.selectedParcelMapping.skipUpload = true;
    this.selectedParcelMapping.overlapMapping = [];
    this.selectNextParcel();
  }

  createNew(): void {
    this.createParcelItemName = this.selectedParcelMapping.featureName;
  }

  overwrite(): void {
    this.selectedParcelMapping.dbId = this.selectedParcelMapping.overlapMapping[0].dbId;
    this.selectedParcelMapping.dbName = this.selectedParcelMapping.overlapMapping[0].dbName;
    this.selectedParcelMapping.overlapMapping = [];
    this.selectNextParcel();
  }

  closeCreateParcel(): void {
    this.createParcelItemName = null;
  }

  onCreateParcel(name: string): void {
    if (name) {
      const shapeFarm = this.shapeFarmNames.find(
        ({ featureName, featureCode }) =>
          featureName === this.selectedParcelMapping.featureFarmName &&
          featureCode === this.selectedParcelMapping.featureFarmCode
      );
      if (shapeFarm) {
        const newParcel = {
          id: getFakeId(),
          Name: name,
          FarmID: shapeFarm.dbId,
          Planted: new Date(this.selectedParcelMapping.plantingDate).toISOString(),
          Created: new Date(this.selectedParcelMapping.creationDate).toISOString()
        };
        this.$emit('onAddNewParcel', this.selectedParcelMappingName, newParcel);
        this.selectedParcelMapping.overlapMapping.forEach((o: OverlapMapping) => {
          const parcel = this.parcels.find((parcel: Parcel) => parcel.id === o.dbId);
          if (parcel) {
            parcel.isDeleted = true;
            parcel.Deleted = moment(new Date(this.selectedParcelMapping.creationDate))
              .subtract(1, 'day')
              .endOf('day')
              .toISOString();
          }
        });
        this.selectedParcelMapping.overlapMapping = [];
        this.selectNextParcel();
      }
    }
    this.closeCreateParcel();
  }

  cancel(): void {
    this.selectedParcelDB = null;
    this.selectedParcelMappingName = null;
    this.cleanupLayer(this.parcelsLayerId, this.parcelsSourceId);
    this.cleanupLayer(this.overlapParcelLayerId, this.overlapParcelSourceId);
    this.$emit('cancel');
  }
}
