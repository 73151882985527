import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Alert, Button, Card, Checkbox, Divider, Input, Spin, Modal, Select, Table, Tag, Radio } from 'ant-design-vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Vue.config.productionTip = false;

Vue.use(Alert);
Vue.use(Button);
Vue.use(Card);
Vue.use(Divider);
Vue.use(Input);
Vue.use(Modal);
Vue.use(Select);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Spin);
Vue.use(Radio);
Vue.use(Checkbox);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue, logErrors: true })]
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
