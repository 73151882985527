let idCounter = 0;
const fakeIdMask = '_fake';

export function getFakeId(): string {
  idCounter++;
  return `${idCounter}${fakeIdMask}`;
}

export function isIdFake(id: string): boolean {
  return id.includes(fakeIdMask);
}
