












































import Mapbox from 'mapbox-gl-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { featureCollection, FeatureCollection, Polygon } from '@turf/helpers';
import bbox from '@turf/bbox';
import { ParcelMapping } from '@/interfaces/parcelMapping';
import * as FileSaver from 'file-saver';
import { SourceParcelOverlapMapping } from '@/interfaces/sourceParcelOverlapMapping';
import { Map } from 'mapbox-gl';

@Component({
  components: {
    Mapbox
  }
})
export default class ManageSourceParcelOverlappingModal extends Vue {
  @Prop() visible: boolean;
  @Prop() sourceParcelOverlapMapping: SourceParcelOverlapMapping[];
  @Prop() shapeParcelNames: ParcelMapping[];
  private map: Map;
  private overlapParcelSourceId = 'overlapParcelSourceId';
  private overlapParcelLayerId = 'overlapParcelLayerId';
  private overlapParcelSourceId2 = 'overlapParcelSourceId2';
  private overlapParcelLayerId2 = 'overlapParcelLayerId2';
  public selectedMappingId: string = null;
  public selectedMapping: SourceParcelOverlapMapping = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  getItemName(mapping: SourceParcelOverlapMapping): string {
    return `${mapping.parcelName} overlap ${mapping.parcelName2} - ${mapping.percent}%`;
  }

  get overlapMessage(): string {
    return `Please validate these problematic parcels (${this.sourceParcelOverlapMapping
      .map((m) => [m.parcelName, m.parcelName2])
      .flat()
      .join(', ')}).`;
  }

  handleChange(mappingId: string): void {
    this.selectedMappingId = mappingId;
    this.cleanupLayer(this.overlapParcelLayerId, this.overlapParcelSourceId);
    this.cleanupLayer(this.overlapParcelLayerId2, this.overlapParcelSourceId2);

    this.selectedMapping = this.sourceParcelOverlapMapping.find(
      (m: SourceParcelOverlapMapping) => m.uniqueId === mappingId
    );
    this.map.addSource(this.overlapParcelSourceId, {
      type: 'geojson',
      data: this.selectedMapping.parcelShape as FeatureCollection<Polygon>
    });
    this.map.addLayer({
      type: 'fill',
      id: this.overlapParcelLayerId,
      source: this.overlapParcelSourceId,
      paint: { 'fill-opacity': 0.5, 'fill-color': 'rgba(200, 100, 240, 1)' }
    });

    this.map.addSource(this.overlapParcelSourceId2, {
      type: 'geojson',
      data: this.selectedMapping.parcelShape2 as FeatureCollection<Polygon>
    });
    this.map.addLayer({
      type: 'fill',
      id: this.overlapParcelLayerId2,
      source: this.overlapParcelSourceId2,
      paint: { 'fill-opacity': 0.5, 'fill-color': 'rgba(28, 120, 153, 1)' }
    });

    const geoBbox = bbox(this.selectedMapping.parcelShape);
    this.map.fitBounds(
      [
        [geoBbox[0], geoBbox[3]],
        [geoBbox[2], geoBbox[1]]
      ],
      { duration: 0 }
    );
  }

  private cleanupLayer(layerId: string, sourceId: string): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  onMapLoaded(map: Map): void {
    this.map = map;
    this.selectNextParcel();
  }

  download(): void {
    FileSaver.saveAs(
      new Blob([
        JSON.stringify(
          featureCollection(
            this.sourceParcelOverlapMapping
              .map((o) => {
                const feature = Object.assign({}, o.parcelShape.features[0]);
                feature.properties.name = o.parcelName;
                const feature2 = Object.assign({}, o.parcelShape2.features[0]);
                feature2.properties.name = o.parcelName2;
                return [feature, feature2];
              })
              .flat()
          )
        )
      ]),
      'source_parcel_overlap.geojson'
    );
  }

  ignoreAll(): void {
    this.sourceParcelOverlapMapping.forEach((m: SourceParcelOverlapMapping) => {
      const parcelMapping = this.shapeParcelNames.find((p: ParcelMapping) => p.dbId === m.parcelId);
      if (parcelMapping) {
        parcelMapping.skipUpload = true;
      }
      const parcelMapping2 = this.shapeParcelNames.find((p: ParcelMapping) => p.dbId === m.parcelId2);
      if (parcelMapping2) {
        parcelMapping2.skipUpload = true;
      }
    });
    this.cancel();
  }

  private selectNextParcel(): void {
    if (this.sourceParcelOverlapMapping.length) {
      this.handleChange(this.sourceParcelOverlapMapping[0].uniqueId);
    } else {
      this.cancel();
    }
  }

  cancel(): void {
    this.selectedMappingId = null;
    this.selectedMapping = null;
    this.cleanupLayer(this.overlapParcelLayerId2, this.overlapParcelSourceId2);
    this.cleanupLayer(this.overlapParcelLayerId, this.overlapParcelSourceId);
    this.$emit('cancel');
  }
}
