













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CreateEntityModal extends Vue {
  @Prop() title: string;
  @Prop() visible: boolean;
  @Prop() preFill: string;
  @Prop() preFillCode: string;
  @Prop() hasCode: boolean;
  name: string = null;
  code: string = null;

  onChange(event: InputEvent): void {
    this.name = (event.target as HTMLInputElement).value;
  }

  onCodeChange(event: InputEvent): void {
    this.code = (event.target as HTMLInputElement).value;
  }
  ok(): void {
    this.$emit('ok', this.name || this.preFill, this.code || this.preFillCode);
    this.name = null;
    this.code = null;
  }

  cancel(): void {
    this.$emit('cancel');
    this.name = null;
    this.code = null;
  }
}
