


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MapEntityModal extends Vue {
  @Prop() title: string;
  @Prop() visible: boolean;
  @Prop() items: [];

  selectedValue = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  ok(): void {
    this.$emit('ok', this.selectedValue);
    this.selectedValue = '';
  }

  cancel(): void {
    this.$emit('cancel');
    this.selectedValue = '';
  }
}
