







import { Component, Vue } from 'vue-property-decorator';
import GlobalLoader from '@/components/GlobalLoader.vue';

@Component({
  components: {
    GlobalLoader
  }
})
export default class App extends Vue {}
